<template>
  <div class="Goods">
    <div class="Goods_box">
      <div class="Goods_list">
        <div
          class="Goods_item"
          v-for="(item, index) in DetailList"
          :key="index"
          :class="`lv${item.lv}`"
          @click="openuser(item)"
        >
          <div class="box-price">
            <div class="dura_alias">{{ item.type_alias }}</div>
          </div>
          <div class="Goods_pic">
            <img :src="item.cover" alt="" />
          </div>
          <div
            class="elips1"
            style="font-size: 0.1rem; margin-top: 0rem; margin-bottom: 0.03rem"
          >
            {{ item.name }}
          </div>
          <div style="font-size: 0.1rem; margin-bottom: 0.03rem">
            {{ item.dura_alias == "无" ? "" : item.dura_alias }}
          </div>
          <div
            class="userbox"
            :class="[`lv${item.lv}`, { clckuserbox: activeIndex == item.id }]"
          >
            <div class="userimg">
              <img :src="item.get_user?.avatar" alt="" />
            </div>
            <div class="username">{{ item.get_user?.name }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    DetailList: {
      //箱内物品列表
      type: Array,
    },
  },
  data() {
    return {
      activeIndex: null,
    };
  },
  created() {
    this.activeIndex = null;
  },
  methods: {
    openuser(v) {
      this.activeIndex = v.id;
    },
  },
};
</script>

<style lang="scss">
.Goods {
  width: 100%;
  // padding: 0.1rem;
  box-sizing: border-box;
  // margin-top: 0.1rem;
  display: flex;
  align-content: space-between;
  flex-wrap: wrap;

  .Goods_box {
    width: 100%;
    padding: 0.08rem 0;
    .Goods_nav {
      width: 100%;
      display: flex;
      color: #fff;

      p {
        width: 1.2rem;
        height: 0.4rem;
        background: #fff;
        color: #000;
        text-align: center;
        line-height: 0.4rem;
        font-size: 0.16rem;
        cursor: pointer;

        &.active {
          background: #ffba2e;
        }
      }
    }

    .history_list {
      width: 100%;
      display: flex;
      padding: 5px 0;
      // flex-wrap: wrap;
      border-radius: 0 4px 4px 4px;
      margin-top: 20px;
      overflow-x: hidden;
      overflow-y: hidden;

      .history_item {
        flex-shrink: 0;
        width: 0.8rem;
        text-align: center;
        font-size: 12px !important;
        color: #fff;
        margin-bottom: 10px;
        margin-right: 5px;
        padding-bottom: 20px;
        background-color: rgba($color: #000000, $alpha: 0.2);
        /*background: url(../../assets/images/public/back_pub.jpg) no-repeat center;*/
        background-size: cover;
        // border-radius: 6px;
        position: relative;
        cursor: pointer;

        .ceng {
          position: absolute;
          width: 50px;
          height: 50px;
          text-align: center;
          transition: 0.3s;
          right: -15px;

          .C_pic {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            overflow: hidden;
            margin: 40px auto 10px;

            img {
              width: 100%;
              height: 100%;
            }

            p {
              font-size: 12px;
              color: #fff;
            }
          }
        }

        // &:hover {
        // 	.ceng {
        // 		top: 0
        // 	}
        // }

        .history_pic {
          width: 100%;
          height: 100px;
          margin: 0 auto;
          display: flex;
          justify-content: center;
          align-items: center;

          background-image: url(http://csgo.api.gameskins.cn/uploads/images/baeef9d5696b72118858f87ff955bee7.png);
          background-position: center;
          background-size: 100%;
          background-repeat: no-repeat;

          img {
            max-width: 100%;
            vertical-align: middle;
          }
        }

        p {
          width: 100%;
          padding: 0 3px;
          box-sizing: border-box;
          font-size: 14px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }

    .Goods_list {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      // max-height: 1.7rem;
      // overflow-y: scroll;
      .Goods_item {
        width: 1.05rem;
        height: 1.12rem;
        padding: 0.03rem 0.05rem 0.05rem;
        // padding-bottom: 0.04rem;
        margin: 0 0.032rem;
        text-align: center;
        color: #fff;
        position: relative;
        overflow: hidden;
        cursor: pointer;
        position: relative;
        margin-bottom: 0.08rem;

        .box-price {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 0.05rem;
        }

        .odds_percent {
          font-size: 0.1rem;
          font-family: Microsoft YaHei;
          font-weight: 400;
          text-align: left;
          // padding-left: 10px;
          box-sizing: border-box;
          color: #dea61f;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .dura_alias {
          // text-align: left;
          // padding-right: 10px;
          box-sizing: border-box;
          color: #fff;
          font-size: 0.1rem;
          font-family: Microsoft YaHei;
          font-weight: 400;
          text-align: center;
        }

        .Goods_pic {
          width: 0.6rem;
          height: 0.46rem;
          display: flex;
          justify-content: center;
          align-items: center;
          background-position: center;
          background-size: 100%;
          background-repeat: no-repeat;
          // padding: 0.1rem 0;
          margin: 0 auto;
          img {
            width: 100%;
          }
        }

        p {
          width: 100%;
          box-sizing: border-box;
          font-size: 0.1rem;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-top: 0.18rem;
          margin-bottom: 0.08rem;
        }

        span {
          display: flex;
          justify-content: center;
          align-items: center;
          color: #75cd66;
          font-size: 0.1rem;
          margin: 5px 0 0;

          img {
            width: 0.2rem;
          }
        }
      }
      .userbox1 {
        position: absolute;
        top: 0;
        right: -0.025rem;
        width: 1.05rem;
        height: 1.12rem;
        padding: 0.03rem 0.05rem 0.05rem;
        // padding-bottom: 0.04rem;
        margin: 0 0.032rem;
        text-align: center;
        color: #fff;
        overflow: hidden;
        margin-bottom: 0.08rem;
      }
      .userbox {
        transform: rotateY(180deg);
        transform-origin: 50% 0;
        transition: all 0.5s;
        opacity: 0;
        position: absolute;
        top: 0;
        right: -0.025rem;
        width: 1.05rem;
        height: 1.12rem;
        padding: 0.03rem 0.05rem 0.05rem;
        // padding-bottom: 0.04rem;
        margin: 0 0.032rem;
        text-align: center;
        color: #fff;
        overflow: hidden;
        margin-bottom: 0.08rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        .userimg {
          width: 0.6rem;
          height: 0.6rem;
          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
        }
        .username {
          width: 100%;
          font-size: 0.12rem;
        }
      }
      .clckuserbox {
        opacity: 1;
        transform: rotateY(0deg);
      }
    }
  }
}
</style>
